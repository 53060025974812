<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">히로시마 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지 </span> 이용 가능<br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfhiroshima/kr/havefun_title_kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfhiroshima/en/ferry_EN.png'),
          title: ['히로시마항-구레항을 잇는 크루즈 페리 편도권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1. 히로시마항 우지나 여객터미널 1층 티켓 판매소','주소 : 히로시마시 미나미구 우지나카이간 1-13-26','2. 구레항(중앙부두) 터미널 1층 티켓 판매소','주소 : 구레시 다카라마치 4-44'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/cruise/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['이용 가능 노선 : ','<br>・히로시마항 → 구레항<br>・구레항 → 히로시마항','<br>※ 위 구간 중 편도 1회에 한하여 승선하실 수 있습니다.'] },
                { cid: 2, text: ['티켓 판매소에서 QR코드를 제시해 주세요. QR코드 인증 없이 바로 승선하실 수 없으므로 주의해 주세요.'] },
                { cid: 3, text: ['히로시마-구레 노선의 고속선(SUPER JET)은 이용하실 수 없습니다.'] },
                { cid: 4, text: ['시간표는 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfhiroshima/en/ferry2_EN.jpg'),
          title: ['히로시마항-미야지마항 고속선 티켓 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1. 히로시마항 우지나 여객터미널 1층 티켓 판매소',
                '주소 : 히로시마시 미나미구 우지나카이간 1-13-26',
                '2. 그랜드 프린스 호텔 히로시마 1층 트래블 데스크',
                '주소 : 히로시마시 미나미구 모토우지나마치 23-1',
                '3. 미야지마항 제3부두 티켓 판매소',
                '주소 : 하쓰카이치시 미야지마초 에비스마치 끝쪽'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/highspeedship/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['이용 가능 노선 : ','<br>・히로시마항 → 미야지마항<br>・프린스 호텔 앞 → 미야지마항<br>・미야지마항 → 히로시마항<br>・미야지마항 → 프린스 호텔 앞','<br>※ 위 구간 이용 시, 1000엔 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['티켓 판매소에서 QR코드를 제시해 주세요. QR코드 인증 없이 바로 승선하실 수 없으므로 주의해 주세요.'] },
                { cid: 3, text: ['시간표는 홈페이지에서 확인해 주세요.'] },
                { cid: 4, text: ['요금 차액이 발생하는 경우, 현장에서 결제해 주세요.'] },
                { cid: 5, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfhiroshima/en/Orizuru_EN.png'),
          title: ['오리즈루 타워 입장권 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒730-0051 히로시마현 히로시마시 나카구 오테마치 1-2-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.orizurutower.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: [' 1,000엔 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['이벤트 등으로 인해 임시 휴관하는 경우가 있으므로, 자세한 사항은 방문 전에 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['재입장 안내','<br>・이용 당일에 한하여 횟수 제한 없이 재입장 가능합니다.<br>・재입장을 희망하시는 분은 게이트 출구에서 직원에게 말씀해 주세요. 손에 스탬프를 찍어드립니다. 재입장 시에 입장권과 스탬프 확인이 필요합니다.'] },
                { cid: 4, text: [
                  '전망대 이용 안내',
                  '<br>・사고 방지를 위해 캐리어나 큰 짐 등 시설 이용에 있어 적합하지 않다고 판단하는 물건의 반입을 거절하는 경우가 있습니다. 1층 입장 게이트 옆 유료 물품보관함을 이용해 주세요.<br>・외부 음식물은 반입할 수 없으므로 사전에 양해 부탁드립니다.<br>・삼각대를 사용한 촬영은 다른 고객에게 피해가 되므로 이용할 수 없습니다.'
                ] },
                { cid: 5, text: ['날씨로 인해 시야가 좋지 않을 경우 또는 강풍 등으로 인해 휴관하는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfhiroshima/en/Peacecle_EN.png'),
          title: ['히로시마시 공유 자전거 "피스쿠루" 1일권 (히로시마 시내)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '히로시마시 미나미구 교바시초 2-29 SS빌딩 1층 (피스쿠루 운영사무소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://docomo-cycle.jp/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['해당 운영사무소 외에 1일권 판매 창구에서는 QR코드를 인증할 수 없습니다.'] },
                { cid: 2, text: ['이용 당일 23:59시까지 이용할 수 있습니다.'] },
                { cid: 3, text: ['자전거는 신장 145cm 이상인 분부터 이용할 수 있습니다.'] },
                { cid: 4, text: ['시내 약 140곳의 전용 사이클 포트에 반납해 주세요. '] },
                { cid: 5, text: ['일부 사이클 포트는 이용 시간 및 반납 시간이 제한되어 있습니다. 자세한 사항은 홈페이지를 확인해 주세요.'] },
                { cid: 6, text: ['자전거를 이용할 때는 헬멧 착용을 권고하고 있습니다. 헬멧을 이용하실 분께는 유료로 대여해 드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfhiroshima/en/ekie_EN.png'),
          title: ['JR히로시마역 앞 쇼핑센터 "ekie" 1,500엔 쇼핑 상품권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '히로시마시 미나미구 마쓰바라초 1-2 (2층 인포메이션 카운터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.ekie.jp/lang/korean.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['계산 시 상품권을 제시해 주세요. (QR코드로는 이용할 수 없으므로 반드시 상품권으로 교환한 후에 이용해 주세요)'] },
                { cid: 2, text: ['해당 상품권은 현금으로 교환 불가하며, 이용하고 남은 잔돈은 드리지 않습니다.'] },
                { cid: 3, text: ['임시 휴관 및 기타 여러 사정으로 인해 이용할 수 없는 경우에도 환불은 불가합니다.'] },
                { cid: 4, text: ['상품권 대상 외 점포: 일본여행, 세븐일레븐 Heart-in, 다테 안과, 모미지 약국, 히로시마역 빌딩 우타노하라 치과, 히로시마역 내과/소아과, 가와이주쿠 마나비스'] },
                { cid: 5, text: ['쇼핑 상품권은 7일간 유효합니다.'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfhiroshima/en/Sozo_EN.png'),
          title: ['"히로시마 오코노미야키 모노가타리 에키마에히로바" 1,500엔 상품권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                "히로시마시 미나미구 마쓰바라초 10-1 히로시마 풀포커스 빌딩 6층 '오코노미야키 소조'"],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://ekimae-hiroba.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['계산 시 상품권을 제시해 주세요. (QR코드로는 이용할 수 없으므로 반드시 상품권으로 교환한 후에 이용해 주세요)'] },
                { cid: 2, text: ['해당 상품권은 현금으로 교환 불가합니다.'] },
                { cid: 3, text: ['도난 및 분실 등에 관해서는 일절 책임지지 않습니다.'] },
                { cid: 4, text: ['임시 휴관 및 만석 등 여러 사정으로 인해 이용할 수 없는 경우에도 환불은 불가합니다.'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfhiroshima/en/Bic_Camera_EN.png'),
          title: ['JR히로시마역 앞 가전제품 판매점 "빅카메라 히로시마 에키마에점" 1,000엔 상품권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                "히로시마시 미나미구 마쓰바라초 5-1 1층 종합 안내 카운터"],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['계산 시 상품권을 제시해 주세요. (QR코드로는 이용할 수 없으므로 반드시 상품권으로 교환한 후에 이용해 주세요)'] },
                { cid: 2, text: ['해당 상품권은 현금으로 교환 불가합니다.'] },
                { cid: 3, text: ['도난 및 분실 등에 관해서는 일절 책임지지 않습니다.'] },
                { cid: 4, text: ['다른 상품권, 엽서, 우표, 담배 및 기타 당사가 지정한 상품 등으로 교환할 수 없습니다.'] },
                { cid: 5, text: ['임시 휴관 및 만석 등 여러 사정으로 인해 이용할 수 없는 경우에도 환불은 불가합니다.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfhiroshima/en/LobbyLounge_EN.jpg'),
          title: ['호텔 그란비아 히로시마 메자닌플로어 "로비 라운지" 케이크 세트'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '히로시마현 히로시마시 미나미구 마쓰바라초 1-5 메자닌플로어 "로비 라운지" (로비 1층)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/ko/hotels/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함 내용 : 파티시에 특제 케이크와 커피 또는 홍차.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.','※임시 휴업 및 만석 등 여러 사정으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                { cid: 3, text: ['상품 내용의 사진은 참고용으로 실제 제공되는 상품과 다를 수 있습니다.'] },
                { cid: 4, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요.'] },
                { cid: 5, text: ['테이크아웃 불가'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfhiroshima/en/dahe_EN.jpg'),
          title: ['야마토 뮤지엄 입장권+뮤지엄 상점 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '입구에서 QR코드를 제시하시면 입장 가능합니다. (티켓교환 불필요)5-20, Takaramachi, Kure City, Hiroshima, 737-0029'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://yamato-museum.com/leaflet/guidance-leaflet-korean/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['입장시에 관내이용쿠폰 1,000엔을 수령해 주세요.'] },
                { cid: 2, text: ['이용쿠폰은 뮤지엄 내 상점에서 이용할 수 있습니다. 잔돈은 환불되지 않습니다. '] },
                { cid: 3, text: ['영업시간은 방문전 공식 사이트에서 확인해 주세요.'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFHiroshima&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>